<template>
  <div>
    <social-button-login
      v-for="method in methods"
      :key="method.id"
      :method="method"
      :oauth="oauth"
    />
  </div>
</template>

<script>
import SocialButtonLogin from './social-button.vue';
export default {
  name: 'SocialButtons',
  components: { SocialButtonLogin },
  props: {
    methods: {
      type: Array,
      required: true
    },
    oauth: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
