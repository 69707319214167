<template>
  <validation-provider
    :default-errors="initialErrors"
    :default-values="computedInitialValues"
    :resolver="resolver"
    instant-validate
    @submit="onSubmit"
  >
    <template #default="{ submitted, handleSubmit }">
      <form-layout
        ref="form"
        :class="$style.wrapper"
        :disabled="loading"
        method="post"
        @submit="handleSubmit"
      >
        <form-template>
          <template #controls>
            <fields-layout>
              <validation-field hidden name="_xsrf" />

              <validation-field name="password" hide-errors :label="$i18n('password.caption')">
                <template #default="{ id, modelValue, onChange, name, invalid }">
                  <base-input
                    :id="id"
                    :value="modelValue"
                    :invalid="submitted && invalid"
                    :name="name"
                    data-qa="account-login-password"
                    type="password"
                    big
                    autocomplete="off"
                    @input-value="onChange"
                  />
                </template>
              </validation-field>
            </fields-layout>
          </template>

          <template #password-errors>
            <validation-errors all>
              <template #nude="{ errors }">
                <password-errors :errors="errors" :rules="passwordRules" :dirty="submitted" />
              </template>
            </validation-errors>
          </template>

          <template #footer>
            <base-button
              size="l"
              color="black"
              type="submit"
              data-qa="account-login-submit"
              :loading="loading"
            >
              {{ $i18n('start.button') }}
            </base-button>

            <div :class="$style.error">
              <validation-errors v-if="submitted" all>
                <template #nude="{ errors }">
                  <form-errors :errors="errors" :exclude="passwordRuleIds" />
                </template>
              </validation-errors>
            </div>

            <div :class="$style.linkContainer">
              <validation-field v-if="isRemember" hidden name="remember" />
              <a :class="$style.link" href="/account/remember" @click.prevent="handleRemember">
                {{ $i18n('remember') }}
              </a>
            </div>
          </template>
        </form-template>
      </form-layout>
    </template>
  </validation-provider>
</template>

<script>
import { object, string } from 'yup';

import BaseButton from '@/components/button/button.vue';
import BaseInput from '@/components/input/input.vue';
import {
  ValidationErrors,
  ValidationField,
  ValidationProvider,
  yupResolver
} from '@/components/ui/validate-form';
import FormLayout from '@/components/ui/form-layout/form-layout.vue';
import FieldsLayout from '@/components/ui/fields-layout/fields-layout.vue';
import FormErrors from '../../components/form-errors/form-errors.vue';
import FormTemplate from '../../components/form-template/form-template.vue';
import PasswordErrors from '../../components/password-errors/password-errors.vue';

export default {
  name: 'MergePure',
  components: {
    FormErrors,
    FieldsLayout,
    FormLayout,
    ValidationField,
    ValidationErrors,
    ValidationProvider,
    BaseButton,
    BaseInput,
    FormTemplate,
    PasswordErrors
  },
  props: {
    passwordRules: {
      type: Array,
      required: true
    },
    initialErrors: {
      type: Object,
      default: () => ({})
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      isRemember: false
    };
  },
  computed: {
    computedInitialValues() {
      return {
        ...this.initialValues,
        remember: true
      };
    },
    resolver() {
      let password = string().default('').required(this.$i18n('password.required'));
      for (const rule of this.passwordRules) {
        password = password.test({
          name: rule.id,
          test: (value) => new RegExp(`${rule.symbols}{${rule.minCount},}`).test(value)
        });
      }
      return yupResolver(
        object({
          password
        })
      );
    },
    passwordRuleIds() {
      return this.passwordRules.map(({ id }) => id);
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$refs.form.$el.submit();
    },
    async handleRemember() {
      this.isRemember = true;
      await this.$nextTick();
      this.onSubmit();
    }
  }
};
</script>

<style module>
.wrapper {
  --title-font-weight: 500;
}

.error {
  margin-top: 15px;
}

.linkContainer {
  margin-top: 20px;
}

.link {
  padding: 0;
  border: none;
  font-size: 18px;
  line-height: 22px;
  color: $blueColor;
  text-decoration: none;
  background: none;

  &:hover {
    color: $linkHoverColor;
  }
}
</style>

<i18n lang="json">
{
  "password.caption": {
    "ru_RU": "Пароль",
    "en_US": "Password"
  },
  "password.required": {
    "ru_RU": "Введите пароль",
    "en_US": "Enter password"
  },
  "start.button": {
    "ru_RU": "Начать работу",
    "en_US": "Start work"
  },
  "remember": {
    "ru_RU": "Восстановить пароль",
    "en_US": "I Forgot Password"
  }
}
</i18n>
