<template>
  <div>
    <div v-if="errors">
      {{ errors }}
    </div>
    <validation-provider
      v-else
      :default-values="initialValues"
      :class="$style.wrapper"
      @submit="onSubmit"
    >
      <template #default="{ handleSubmit }">
        <div v-html="$i18n('email.text', { email: email })" />
        <form-layout ref="form" :disabled="loading" method="post" @submit="handleSubmit">
          <form-template>
            <template #controls>
              <fields-layout>
                <validation-field hidden name="_xsrf" />
                <validation-field hidden name="code" />
                <validation-field hidden name="request_id" />
              </fields-layout>
            </template>
            <template #footer>
              <base-button
                size="l"
                color="black"
                type="submit"
                data-qa="account-login-revert"
                :loading="loading"
              >
                {{ $trlMessage('button.remember') }}
              </base-button>

              <div :class="$style.error">
                <validation-errors all />
              </div>
            </template>
          </form-template>
        </form-layout>
      </template>
    </validation-provider>
  </div>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import {
  ValidationProvider,
  ValidationField,
  ValidationErrors
} from '@/components/ui/validate-form';
import FormLayout from '@/components/ui/form-layout/form-layout.vue';
import FieldsLayout from '@/components/ui/fields-layout/fields-layout.vue';
import FormTemplate from '../../components/form-template/form-template.vue';

export default {
  name: 'RevertForm',
  components: {
    FieldsLayout,
    FormLayout,
    ValidationErrors,
    ValidationField,
    ValidationProvider,
    BaseButton,
    FormTemplate
  },
  props: {
    initialErrors: {
      type: Object,
      default: () => ({})
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      email: '',
      loading: false
    };
  },
  computed: {
    errors() {
      const { errors } = this.initialValues;
      if (!errors) {
        return '';
      }
      if (errors.error === 'email_registered_by_colleague') {
        return this.$i18n(errors.error, {
          org: errors.organization_name,
          type: this.$i18n(errors.member_type)
        });
      } else {
        return this.$i18n(errors.error);
      }
    }
  },
  mounted() {
    if (this.errors) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObj = Object.fromEntries(queryParams.entries());
    this.email = paramsObj.email;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$refs.form.$el.submit();
    }
  }
};
</script>

<style module>
.wrapper {
  --title-font-weight: 500;
}
.error {
  margin-top: 15px;
}
</style>

<i18n lang="json" base="@/components/email-verification/assets/common.lang.json">
{
  "email.text": {
    "ru_RU": "Вы сможете снова использовать почту '<strong>'{ email }'</strong>' для входа в ваш аккаунт",
    "en_US": "You will be able to use email '<strong>'{ email }'</strong>' to log into your account again"
  },
  "button": {
    "ru_RU": "Восстановить почту",
    "en_US": "Recover email"
  }
}
</i18n>
